import React from 'react'
import { graphql } from 'gatsby'

import TermDetail from '../layouts/TermDetail'

interface Props {
  data: Queries.ProductDetailQuery
}

const ProductDetail = ({ data }: Props) => {
  const page = data.datoCmsTermProduct
  const generic = data.datoCmsLandingPage
  return (
    <TermDetail
      heroProps={{
        mainHeading: page.name,
        imageLandscape: generic.imageLandscape.gatsbyImageData,
        imagePortrait: generic.imagePortrait.gatsbyImageData,
      }}
      seoTags={page.seoMetaTags}
      seoImage={generic.metaImage.gatsbyImageData}
      mandates={data.allDatoCmsPageMandate.edges}
      posts={data.allDatoCmsBlogPost.edges}
      news={data.allDatoCmsIndustryNews.edges}
    />
  )
}

export const query = graphql`
  query ProductDetail(
    $slug: String!
    $mandateLimit: Int
    $newsLimit: Int
    $blogLimit: Int
  ) {
    #
    # ****** PAGE
    #
    datoCmsLandingPage(originalId: { eq: "1654659" }) {
      ...SecondaryTermDetail
    }
    datoCmsTermProduct(slug: { eq: $slug }) {
      textSectionHeroText
      name
      slug
      originalId
      model {
        apiKey
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    #
    # ****** MANDATES
    #
    allDatoCmsPageMandate(
      filter: { products: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: [active, meta___createdAt], order: [DESC, DESC] }
      limit: $mandateLimit
    ) {
      edges {
        node {
          ...mandateCard
        }
      }
    }
    #
    # ****** BLOG POSTS
    #
    allDatoCmsBlogPost(
      filter: { products: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: [publishDate], order: DESC }
      limit: $blogLimit
    ) {
      edges {
        node {
          ...BlogPostCard
        }
      }
    }
    #
    # ****** NEWS
    #
    allDatoCmsIndustryNews(
      filter: { products: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: [date], order: DESC }
      limit: $newsLimit
    ) {
      edges {
        node {
          ...News
        }
      }
    }
  }
`

export default ProductDetail
